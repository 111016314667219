<template>
	<!--====================  header area ====================-->
	<div class="header-area">
		<div class="header-area__desktop header-area__desktop--default">
			<!--=======  header info area  =======-->
			<div class="header-info-area" style="padding: 10px">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-12">
							<div class="header-info-wrapper align-items-center">
								<!-- logo -->
								<div class="logo">
									<div class="row">
										<div class="col-9">
											<router-link to="/">
												<img
													src="/assets/img/logo/services-like-u-group.svg"
													class="img-fluid"
													alt="Brand Logo"
												/>
											</router-link>
										</div>
										<div
											class="col-3 user-icon"
											style="padding: 0px; text-align: right; display: none"
										>
											<div class="row">
												<img
													src="/assets/img/icons/user.svg"
													style="width: 20%"
													@click="mobiletoggleClass('addClass', 'active')"
													alt="User"
												/>
												<h6
													class="header-info-single-item__title pr-1"
													style="padding: 10px 0px; margin: auto"
												>
													{{ name }}
												</h6>
											</div>
										</div>
									</div>
								</div>
								<!-- mobile menu -->
								<div
									class="mobile-navigation-icon"
									style="text-align: right"
									id="mobile-menu-trigger"
									@click="mobiletoggleClass('addClass', 'active')"
								>
									<i></i>
								</div>
								<!-- header contact info -->
								<div class="header-contact-info">
									<div v-if="!name" class="header-info-single-item">
										<div class="header-info-single">
											<img
												src="/assets/img/icons/user.svg"
												style="width: 40px; padding-right: 5px"
												alt="User"
											/>
										</div>
										<div class="header-info-single-item__content">
											<h6 class="header-info-single-item__title">
												<router-link to="/login" class="pr-1">Iniciar Sesión</router-link
												>|
												<router-link to="/signup" class="pr-1">Regístrate</router-link>
											</h6>
										</div>
									</div>
									<div v-if="name" class="header-info-single-item">
										<div class="header-info-single">
											<img
												src="/assets/img/logo/Logo_Bolsita_border.png"
												style="width: 40px; padding-right: 5px"
												alt="Shopping Cart"
											/>
										</div>
										<div class="header-info-single-item__content">
											<h6 class="header-info-single-item__title">
												<router-link to="/carrito" class="pr-1">Solicitudes</router-link>
												<span
													class="badge badge-pill"
													style="background-color: #e94e1b; color: #fff"
													>{{ itemsNumber }}</span
												>
												|
												<router-link to="/ordenes" class="pr-1">Ordenes</router-link>
												<span
													class="badge badge-pill"
													style="background-color: #e94e1b; color: #fff"
													>{{ ordersNumber }}</span
												>
											</h6>
											<p class="header-info-single-item__subtitle">
												Adquiere nuestros servicios
											</p>
										</div>
									</div>
									<div v-if="name" class="header-info-single-item">
										<div class="header-info-single">
											<img
												src="/assets/img/icons/user.svg"
												style="width: 40px; margin-right: 10px"
												alt="User"
											/>
										</div>
										<div class="header-info-single-item__content">
											<h6 class="header-info-single-item__title pr-1">
												Hola, {{ name }}
											</h6>
											<p class="header-info-single-item__subtitle">
												<a @click.prevent="logout">Cerrar sesión</a>
											</p>
										</div>
									</div>
									<div class="header-info-single-item">
										<div class="header-info-single">
											<div class="header-navigation__nav position-static">
												<nav id="countries-list">
													<ul>
														<li class="has-children has-children--multilevel-submenu">
															<a style="padding: 0px"
																><img
																	:src="flagUrl"
																	style="width: 40px; padding-right: 5px"
																	alt="Flag"
															/></a>

															<ul
																class="submenu"
																style="left: -150px; min-width: 0px; padding: 0"
															>
																<li>
																	<a @click="changeCountry('047-panama')">Panamá</a>
																</li>
																<li>
																	<a @click="changeCountry('022-european-union')"
																		>Unión Europea</a
																	>
																</li>
																<li>
																	<a @click="changeCountry('057-venezuela')">Venezuela</a>
																</li>
															</ul>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--=======  End of header info area =======-->

			<!--=======  header navigation area  =======-->
			<fixed-header>
				<div
					class="header-navigation-area default-bg"
					style="backgroung-color: #111; heid"
				>
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<!-- header navigation -->
								<div
									class="header-navigation header-navigation--header-default position-relative"
								>
									<div class="header-navigation__nav position-static">
										<nav>
											<ul>
												<li>
													<router-link to="/">Inicio</router-link>
												</li>

												<li>
													<router-link to="/nosotros">Nosotros</router-link>
												</li>

												<li class="has-children has-children--multilevel-submenu">
													<a href="/#services">Servicios</a>
													<ul class="submenu">
														<li
															v-for="list in services.serviceSidebar.lists"
															:key="list.id"
														>
															<router-link :to="list.url" v-if="list.show">
																<!--<i :class="list.icon"></i>-->
																{{ list.name }}
															</router-link>
														</li>
													</ul>
												</li>

												<li>
													<router-link to="/contacto">Contáctanos</router-link>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</fixed-header>
			<!--=======  End of header navigation area =======-->
		</div>
	</div>
	<!--====================  End of header area  ====================-->
</template>

<script>
	import FixedHeader from 'vue-fixed-header'
	import axios from 'axios'
	import services from '../data/service.json'
	//import { isMobile } from "mobile-device-detect";
	var urlLogout = process.env.VUE_APP_AUTH_API + 'api/auth/logout'
	export default {
		data() {
			return {
				services,
				orders: 0,
				country: '057-venezuela',
				flagUrl: '/assets/img/icons/057-venezuela.svg',
				cartItemsNumber: 0,
			}
		},
		components: {
			FixedHeader,
		},
		created() {
			this.checkLogin()
			this.name
			this.lastname
			this.itemsNumber
			this.country = sessionStorage.getItem('country')
			this.flagUrl = '/assets/img/icons/' + this.country + '.svg'
		},
		mounted() {},
		methods: {
			changeCountry(country) {
				sessionStorage.setItem('country', country)
				this.flagUrl = '/assets/img/icons/' + country + '.svg'
				location.reload()
			},
			checkLogin() {
				if (sessionStorage.getItem('token') != null) {
					axios
						.get(process.env.VUE_APP_AUTH_API + 'api/auth/user', {
							headers: {
								Authorization: `Bearer ` + sessionStorage.getItem('token'),
							},
						})
						.then((response) => {
							//console.log(response.data);
							// console.log(JSON.parse(sessionStorage.getItem("order")));
							if (
								sessionStorage.getItem('items') != null &&
								sessionStorage.getItem('items') != 0
							) {
								this.cartItemsNumber = sessionStorage.getItem('items')
							}
						})
						.catch((error) => {
							/*this.$swal({
              title: "Sesión finalizada",
              text: "Por favor, inicia sesión nuevamente",
              icon: "info",
              showCancelButton: false,
              showConfirmButton: true
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push("/login");
              }
            });
            this.logout();*/
							console.log(error)
						})
				}
			},

			// offcanvas mobilemenu
			mobiletoggleClass(addRemoveClass, className) {
				const el = document.querySelector('#offcanvas-menu')
				if (addRemoveClass === 'addClass') {
					el.classList.add(className)
				} else {
					el.classList.remove(className)
				}
			},

			logout() {
				this.$swal({
					title: `Hasta pronto ` + this.name + ' ' + this.lastname,
					html: `Regresa cuando quieras para seguir disfrutando de nuestros servicios`,
					showCancelButton: false,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				axios
					.get(urlLogout, {
						headers: {
							Authorization: `Bearer ` + sessionStorage.getItem('token'),
						},
					})
					.then((response) => {
						setTimeout(() => (location.href = '/'), 5000)
					})
					.catch((error) => {
						console.log(error)
						this.error = true
					})
				sessionStorage.clear()
			},
		},
		computed: {
			name() {
				if (sessionStorage.getItem('name') !== null) {
					return sessionStorage.getItem('name')
				} else {
					return ''
				}
			},

			lastname() {
				if (sessionStorage.getItem('lastname') !== null) {
					return sessionStorage.getItem('lastname')
				} else {
					return ''
				}
			},

			ordersNumber() {
				if (sessionStorage.getItem('ordersNumber') !== null) {
					return sessionStorage.getItem('ordersNumber')
				} else {
					return 0
				}
			},

			itemsNumber() {
				if (
					sessionStorage.getItem('order') != null &&
					sessionStorage.getItem('order') != 0
				) {
					return JSON.parse(sessionStorage.getItem('order')).items.length
				} else {
					return 0
				}
			},
		},
	}
</script>

<style lang="scss">
	.vue-fixed-header--isFixed {
		left: 0;
		top: 0;
		width: 100vw;
		z-index: 999;
		position: fixed;
		box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
		animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
	}

	.default-bg {
		background-color: #111;
	}

	.header-navigation--header-default .header-navigation__nav nav > ul > li > a {
		font-size: 20px;
		padding: 10px;
	}

	#countries-list ul li ul li a:hover,
	#countries-list ul li ul li a:focus {
		background: #e94e1b45;
	}

	.login-form input {
		width: 100%;
		height: 30px;
		background-color: transparent;
		border: 1px solid #e5e5e5;
		border-radius: 0;
		line-height: 20px;
		padding: 10px 20px;
		font-size: 14px;
		margin-bottom: 15px;
	}

	@media only screen and (max-width: 500px) {
		.user-icon {
			display: block !important;
		}
	}
</style>
